@import '../../../variables.scss';
#edit-profile-dialog .modal-content {
  padding-left: 1.25rem
}

#edit-profile-dialog .vai-label--m {
    width: $input-label-width;
}
.react-tel-input .form-control{
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
    border: solid 1px #b5b5b5;
    color: #003462;
    background-color: #ffffff;
    font: 400 14px "Source Sans Pro", sans-serif;
    line-height: 24px;
    height: 24px;
    width: 154px;
    padding-left: 42px;
}
#edit-profile-dialog .edit-profile-flex{
    display: flex;
}
.edit-profile-asterisk{
    position: absolute;
    left: -14px;
    line-height: 23px;
}
#edit-profile-dialog .react-tel-input{
    margin-left: 8px;
}
.edit-profile-phone-number-label{
    width: 150px
}
.edit-profile-phone-error{
    margin-left: 100px;
    margin-bottom: 20px;
}