.edit-groups-description {
  margin-top: 1.25rem;
}
.edit-groups-button-container {
  text-align: center;

  .modal-footer__end & {
    margin-top: 1.5rem;
  }
}
