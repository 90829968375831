.my-profile-info-container{
    height:500px;
}
.view-my-profile-modal div{
    width:initial
}
.my-profile-heading{
    margin-top: 0px;
}
.my-profile-table{
    width: 100%;
}
.my-profile-user-group-icon{
    padding-right: 20px;
    color: #003462;
}
.my-profile-full-name{
    margin-left: 20px;
    font-size: 21px;
}
.my-profile-header{
    font-size: 18px;
    font-weight: bold;
    width: 150px;
}
.my-profile-top-right-button{
    min-width: 100px;
    white-space: nowrap;
}
.my-profile-top-right-button-container{
    font-size: 14px;
    margin-left: auto;
    width: 150px;
}
.my-profile-borderless-table td{
    border: none;
}
.view-my-profile-table-user-count{
    white-space: nowrap;
}
#my-profile-visibility-icon-button{
    border: none;
    background-color: transparent;
}
#view-my-profile-dialog [role='dialog']{
    width: auto;
}
#my-profile-info-table{
    white-space: nowrap;
}
