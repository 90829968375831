@import '@vaisala/rockhopper-design-tokens/_variables.scss';

#events_filter_bar_filterByZone_modal {
  .accordion-wrapper {
    border: 1.5px solid black;
    height: 355px;
    overflow-y: auto;
    margin: $vai-spacing-s 0;
  }

  .error-text {
    min-height: 20px;
    margin: $vai-spacing-s 0;
  }
}