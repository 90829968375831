.location-header {
  width: 100%;

  &__icon-flex-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin: 0;
    align-items: center;
    word-break: break-word;

    &-flex-item {
      display: flex;
      flex-direction: column;
      justify-items: center;
    }
  }

  &__toggle {
    margin-top: 4px;
  }

  &__description {
    word-break: break-word;
  }
}
