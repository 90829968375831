@import '../../../_variables.scss';

.empty-state {
  &__container {
    max-width: 310px;
    margin: 0 auto;
    padding: 10vh 1rem 1rem;
    text-align: center;
    color: $vai-color-grey-medium;
  }

  &__heading {
    color: $vai-color-grey-medium;
  }

  &__body-text {
    color: $vai-color-grey-medium;
  }

  &__link {
    color: $vai-color-grey-medium;
    text-decoration: underline;
    background: none;
    border: none;
    outline: none;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
