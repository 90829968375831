@import '../../../node_modules/@vaisala/rockhopper-design-tokens/variables.scss';

#events-event-filter-bar {
  background-color: rgba(128, 128, 128, 0.2);
  display: flex;
  flex-flow: row wrap;
  padding: 0.75rem 0.5rem;
  justify-content: space-between;

  @media only screen and (min-width: 1500px) {
    // set filter bar to one line when above large breakpoint
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #time-picker {
    & > #from-time-picker, > #to-time-picker {
      max-width: 62px;
      height: var(--vai-spacing-l);
    }
    .vai-button__icon-end {
      margin-left: 2px;
    }
  }
  .form-item-datepicker {
    .vai-datepicker {
      // enforce correct size for datepicker input
      max-width: 104px;
      @media only screen and (min-width: 400px) {
        max-width: 108px;
      }
    }
  }

  .vai-form-item {
    // adjust form-item defaults inside filter-bar
    display: flex;
    flex-flow: column;
    margin-bottom: 0.75rem;

    &__label {
      min-width: 100%;
    }

    .vai-label {
      font-weight: 600;
    }

    &.empty-label {
      & > .vai-label {
        min-height: 1.5rem;
      }
    }
  }

  #events-apply-button {
    &.vai-button--s {
      margin-top: 24px;
    }
  }

  #events-filter-button {
    // adjust margins and icon alignemnt inside filter button
    margin-right: 8px;
    .vai-button__icon-start {
      margin-right: 4px;
    }
    .vai-button__text {
      margin-right: 4px;
    }
    // fix missalignemnt for items without label
    &.vai-button--s {
      margin-top: 24px;
    }

    &.active {
      color: #006795;
      border-color: transparent;
      border-top-color: #b5b5b5;
      background: #e8e8e8;

      .vai-button__icon-start {
        transform: rotate(180deg);
        transition: transform 0.12s ease-out;
      }
    }
  }
  #events-filter-zone-button,
  #events-filter-clear-button {
    margin-top: 24px;

    @media only screen and (min-width: 769px) and (max-width: 841px) {
      margin-top: 0;
    }
  }

  #events-filter-clear-button {
    @media only screen and (min-width: 300px) and (max-width: 420px) {
      margin-top: 0;
    }
  }

  .events-event-filter {
    /*
      Manage sections in the filterbar.
      Sizing is mostly flexible using grid but includes some fixed sizes in different breakpoints
      Change section orders depending on breakpoint layout
      Hide filters on smaller screens and transition them when .open class is added.
    */

    &-search {
      display: flex;
      flex-flow: row nowrap;
      order: 1;
      flex-grow: 1;
      align-items: center;

      .events-event-filter-bar-search {
        width: 100%;
      }
    }
    &-filter {
      display: flex;
      order: 2;
      align-items: flex-start;
    }
    &-apply {
      display: flex;
      order: 3;
      flex-direction: row-reverse;
      align-items: flex-start;
      margin-left: auto;
      @media only screen and (min-width: 900px) {
        margin-top: 18px;
      }
    }
    &-date {
      display: flex;
      order: 4;
      align-items: center;
      flex-flow: row wrap;
      width: 100%;
    }

    &-extras {
      display: flex;
      flex-flow: row wrap;
      order: 5;
      height: 0;
      opacity: 0;
      transition: height 0.18s ease-out, opacity 0.22s ease;
      width: 100%;
      align-items: flex-start;

      &.show {
        height: 140px;
        opacity: 1;

        @media only screen and (min-width: 666px) {
          height: 60px;
        }
        @media only screen and (min-width: 769px) and (max-width: 841px) {
          height: 100px;
        }
      }
    }
    @media only screen and (min-width: 960px) {
      &-search {
        flex-grow: 1;
        width: 25%;
        max-width: 40%;
      }
      &-date {
        order: 2;
        width: auto;
      }
      &-filter {
        order: 3;
      }
      &-apply {
        order: 4;
      }
    }

    @media only screen and (min-width: 1500px) {
      &-search {
        width: 10%;
        flex-grow: 1;
        max-width: 25%;
      }

      &-filter {
        display: none;
      }
      &-extras {
        order: 3;
        height: auto;
        opacity: 1;
        width: auto;
      }
      &-apply {
        order: 4;
        width: auto;
        flex-shrink: 1;
        margin-left: 1rem;
      }
    }
  }
}
