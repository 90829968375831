@import '../../variables';

.rights-accordion-tree-container {
  & #accordion-tree {
    padding-left: $vai-spacing-s;
    background: white;
    display: flex;
    flex-direction: column;
    .vai-accordion__inner-content {
      padding: 0;
    }
    & .vai-accordion__link {
      padding: $vai-spacing-s 0;
      margin: $vai-spacing-xxs 0;
      background-color: #e8e8e8;
      .vai-accordion__title {
        color: $vai-color-blue-dark;
        font-weight: 700;
        font-style: normal;

        @include respond(sm) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .checkbox-container {
      gap: $vai-spacing-m;
      padding: $vai-spacing-s;
    }
  }
}
