@import '../../../../node_modules/@vaisala/rockhopper-design-tokens/variables.scss';

#locations-filter-bar {
  background-color: $vai-color-background-grey;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 50px;
  // justify-content: space-between;
  padding: $vai-spacing-s;
  margin-bottom: $vai-spacing-s;

  .item-left {
    display: flex;
    flex-wrap: wrap;

    .empty-label > .vai-label {
      min-height: 1.15rem;
    }

    .date-pickers-wrapper {
      gap: 8px;
    }

    .filter-span-wrapper {
      margin-right: 15px;
      gap: 5px;

      .vai-button + .vai-button {
        margin-left: 0;
      }
    }

    .form-item {
      .vai-form-item__value {
        margin-right: 0;

        & > div {
          gap: 5px;
        }
      }
    }
  }

  .item-left .vai-form-item:first-child .vai-label {
    width: 100%;
  }

  & .item-left .vai-form-item,
  .item-right .vai-form-item {
    display: block;
    margin-bottom: 0;
  }

  .item-right {
    .vai-form-item__value {
      margin-right: 0;
    }

    .empty-label > .vai-label {
      min-height: 1.15rem;
    }
  }
}
