@import '../../variables';

.loadMoreRows {
  margin-right: 28px;
  inline-size: 180px;
  padding-block: 8px;
  padding-inline: 16px;
  position: absolute;
  inset-block-end: 8px;
  inset-inline-end: 8px;
  line-height: 35px;
  background: #e6f5fa;
}

#events-table {
  .vai-table-custom-row:hover:not(.row-selected) {
    cursor: pointer;
  }
}

.react-grid-Row {
  & > .react-grid-Cell:nth-of-type(1) {
    & > .react-grid-Cell__value {
      color: $vai-color-aqua-vaisala;
    }
  }
}
.react-grid-Grid {
  .vai-table-custom-row {
    border: 1px solid transparent;

    &.row-selected {
      border: 1px solid $vai-color-aqua-vaisala;
      .react-grid-Cell {
        background-color: var(--vai-color-blue-light-2);
      }
    }
  }
}
.vai-data-table .react-grid-Cell {
  padding: 0 4px;
}
