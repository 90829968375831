@import '../../variables';

.modal {
  overflow-wrap: break-word;
  &-header {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-wrap: break-word;
    margin-bottom: $vai-spacing-l;

    &__heading {
      margin: 0;
      padding: 0 1.25rem; // to center and not overflow icon and close.
      overflow-wrap: break-word;
    }
    &__icon {
      position: absolute;
      left: 0;
      top: 4px;
    }
    &--alert {
      & > .modal-header__heading {
        padding: 0 1.75rem 0 2.25rem;
      }
    }
    &--icon {
      & > .modal-header__heading {
        padding: 0 1.25rem 0 1.5rem;
      }
    }
  }
  &-content {
    position: relative;
    & > .vai-form-item:last-child {
      margin-bottom: 0; // fixes excessive spacing between content and footer when vai-form-items present.
    }
  }

  &-footer {
    text-align: center;

    &__actions {
      margin: 1.5rem 0 0.5rem;
    }
  }
}
.alert-dialog {
  &--icon {
    color: $vai-color-orange-dark;
    margin-left: 6px;
  }
}
