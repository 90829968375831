@import '../../../../_variables.scss';

.display-row {
  margin-bottom: 14px !important;

  & h2,
  h3 {
    margin: 0
  }
}

.no-template-msg {
  .vai-icon {
    display: inline-block;
    margin-right: $vai-spacing-m;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

#alarm-threshold-modal {

  & .steps-container,
  & .modal-content,
  & .modal-container {
    height: 100%;
  }

  & .modal-content {
    min-height: 0;

    &>div {
      height: 100%;
    }
  }

  .vai-modal__box--l {
    width: 950px;
    height: 600px;

    @include respond(md) {
      min-width: 90%;
      max-width: 90%;
    }

    @include respond(sm) {
      min-height: 90%;
      max-height: 90%;
    }
  }

  .vai-paper {
    min-width: 878px;
    padding: 16px 36px;
    box-shadow: none;
    height: 100%;

    @include respond(md) {
      min-width: 90%;
      padding: 13px 14px;
    }

    @include respond(sm) {
      min-width: 90%;
    }
  }

  .modal-container {
    position: relative;
  }

  // So that back button stick to the ^ modal-container.
  .modal-content {
    position: static;
  }

  .controls-back-button {
    position: absolute;
    top: 0%;
  }

  .vai-modal__box {
    overflow: visible;
  }
}

#alarm-threshold-warning-message {
  text-align: center;
  margin-bottom: $vai-spacing-xl;

  & h1 {
    margin-bottom: 0;
  }
}