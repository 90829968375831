@import '@vaisala/rockhopper-design-tokens/_variables.scss';

.date-wrapper {
  color: $vai-color-blue-dark;
  min-width: 500px;
  margin: auto;
  margin-bottom: 45px;

  .date-separator {
    color: $vai-color-blue-dark;
  }

  .arrow {
    color: $vai-color-aqua-vaisala;
    cursor: pointer;

    &.disabled {
      color: $vai-color-grey-medium;
      cursor: auto;
      pointer-events: none;
    }
  }

  .right-edge-icon {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 45%;
      border-right: 2px solid $vai-color-aqua-vaisala;
      top: 7px;
      right: 5px;
    }

    &.disabled::after {
      border-right: 2px solid $vai-color-grey-medium;
    }
  }
}
