#events-event-detail {
  .header {
    display: flex;
    justify-content: space-between;

    .navigation {
      display: flex;
      width: 95%;
      justify-content: space-between;

      .heading {
        margin: 0;
        margin-bottom: 1rem;
        min-width: var(--vai-font-size-m);
        &.left {
          margin-left: var(--vai-spacing-xl);
        }
        &.right {
          margin-right: var(--vai-spacing-m);
        }
        .icon {
          color: #009ac8;
        }
      }
    }
    .close {
      .heading {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .container {
    overflow-x: hidden;

    .details {
      margin-bottom: 5px;
      border: 3px solid var(--vai-color-aqua-vaisala);

      .title {
        margin: 0.7rem 1rem;
      }
      .value {
        line-height: 1rem;
        margin: 0.7rem 1rem;
      }
    }
    #events-event-detail-table {
      .react-grid-Canvas {
        overflow-y: hidden !important;
      }
    }
  }
}
