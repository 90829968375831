@import '../../../_variables.scss';

.status-description {
  color: $vai-color-grey-medium;
}

.status-collection {
  border-top: 1px solid $vai-color-grey-light-3;
  border-bottom: 1px solid $vai-color-grey-light-3;
}

.status-value {
  display: flex;
  border-left: 11px solid;
  padding: 1.525rem 0 1.525rem 0.63rem;

  &--unlinked {
    border-color: $vai-color-aqua-vaisala;
  }

  &--active {
    border-color: $vai-color-alert-ok;
  }

  &--inactive {
    border-color: $vai-color-grey-medium;
  }

  &--stale {
    border-color: $vai-color-alert-warning;
  }

  &--warning {
    border-color: $vai-color-alert-warning;
  }

  &--critical {
    border-color: $vai-color-alert-alarm;
  }

  &--info {
    border-color: $vai-color-alert-info;
  }

  &__title.vai-body-text {
    font-size: 3.38rem;
    font-weight: 600;
    line-height: 40px;
    margin: 0;

    &--measurement.vai-body-text {
      font-size: 3.13rem;
    }
  }

  &__settings {
    color: $vai-color-aqua-vaisala;
    text-decoration: none;

    &::hover {
      text-decoration: underline;
    }
  }
}

.status-item {
  color: $vai-color-blue-dark;
  padding: 0.75rem 0.5rem;

  &--error & {
    &__value {
      color: $vai-color-red;
    }
  }

  &--critical {
    background-color: rgba($vai-color-alert-alarm, 0.16);
  }

  &--warning {
    background-color: rgba($vai-color-alert-warning, 0.26);
  }

  &--info {
    background-color: $vai-color-blue-light;
  }

  &__label.vai-body-text {
    margin-bottom: 2px;
    color: $vai-color-grey-medium;
  }

  &__value.vai-body-text {
    margin-bottom: 2px;
  }

  &__vertical-separator {
    width: 1px;
    height: 32px;
    background-color: #cfcfcf;
    border-style: none;
    align-self: center;
  }

  &__icon {
    margin: 0 1rem;

    &--ok {
      color: $vai-color-alert-ok;
      margin: 0 0.5rem 0 0;
    }

    &--sync {
      color: $vai-color-blue-dark;
      margin: 0 0.5rem 0 0;
    }
  }
}
.device-status-infotip {
  max-width: 270px;

  &__icon {
    margin-left: 0.5rem;
  }
}

.alarm-status-value > * {
  margin-right: $vai-spacing-s;
}
