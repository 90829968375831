@import '../../../variables.scss';

.infotip {
  &:hover {
    cursor: pointer;
    opacity: $vai-opacity-level-85;
  }
}

.infotip-popover {
    min-width: 230px;
}
