@import '../../../../variables';

.alarm-setting-form-row {
  &.disabled * {
    color: $vai-color-grey-medium !important
  }

  &.enabled *:not([class*='vai-icon']) {
    color: $vai-color-blue-dark !important
  }

  padding-bottom: $vai-spacing-s;
  padding-top: $vai-spacing-s;

  &:not(:first-child) {
    background-color: $vai-color-grey-light;

    border: 1px solid $vai-color-grey;
    margin-bottom: $vai-spacing-m !important;

    &.enabled {
      border: 1px solid $vai-color-aqua-vaisala;

      &.has-error {
        border: 1px solid $vai-color-red
      }

      & .has-error>input {
        border: 1px solid $vai-color-red
      }

    }

    &.has-warning {
      border: 1px solid $vai-color-orange-dark
    }

    & .has-warning>input {
      border: 1px solid $vai-color-orange-dark
    }

    &>.vai-col:not(:first-child) {
      border-left: 1px solid $vai-color-grey-medium;
    }
  }

  .minutes-hours-box {
    & .time-unit {
      margin-left: $vai-spacing-xs;
      margin-right: $vai-spacing-s;
    }
  }

  .measurement-unit-box {
    text-align: center;
    margin-left: $vai-spacing-s;
  }

  & input,
  .vai-input-field__wrapper,
  .alarm-priority-select {
    width: 100%;
  }

  & input,
  .vai-input-field__wrapper {
    height: 30px;
  }

  &>.vai-col {
    padding: $vai-spacing-xs $vai-spacing-m !important;
  }

  & .vai-row {
    align-items: center;
  }

  & .rc-select-selector {
    border: none !important;
  }

  .activate-checkbox {
    height: 100%;

    & div:first-child {
      height: 100%;
    }

    & input {
      margin: 0;
    }
  }

  @media (max-width: $vai-breakpoint-m) {
    & .vai-col {
      padding: $vai-spacing-xs $vai-spacing-s !important;
    }
  }

  .alarm-display {
    height: 100%;
  }
}
