.multi-state-checkbox {
  margin-right: 10px;
  .vai-checkbox--disabled {
    color: inherit;

    & * {
      cursor: default !important;
    }

    &.vai-checkbox--checked {
      &::before {
        background-color: #003462;
      }
    }
  }

  &.unchecked-inverted {
    .vai-checkbox {
      &::before {
        background-color: #009ac8;
      }

      &::after {
        top: 2px;
        left: 0px;
        content: '\e911';
        color: #009ac8;
        line-height: 1;
        background-color: #ffffff;
        font-family: vaisala-ui, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
      }
    }

    .vai-checkbox--disabled {
      &::after {
        color: #003462;
        background-color: #ffffff;
      }
    }
  }

  &.unchecked[data-readonly='true'] {
    .vai-checkbox {
      &::before {
        background-color: transparent !important;
        border: none;
      }

      &::after {
        top: -2px;
        left: 4px;
        content: '\2212';
        font-weight: 900;
      }
    }
  }

  &.checked-inverted {
    .vai-checkbox--checked {
      &::before {
        background-color: #ffffff !important;
      }

      &::after {
        color: #009ac8 !important;
      }
    }

    .vai-checkbox--disabled {
      &::after {
        color: #003462;
      }
    }
  }

  &.checked-inverted[data-readonly='true'] {
    .vai-checkbox--checked {
      &::before {
        background-color: transparent !important;
        border: none;
      }

      &::after {
        color: #003462 !important;
      }
    }

    .vai-checkbox--disabled {
      &::after {
        color: #003462;
      }
    }
  }
}
