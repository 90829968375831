@import '@vaisala/rockhopper-design-tokens/_variables.scss';

.vai-tree__node {
  &--disabled {
    color: $vai-color-grey-medium;
    font-style: italic;
  }

  &-icon {
    & > .vai-icon {
      font-size: 20px;
    }
  }

  &-label span {
    word-break: break-word;
  }
}

// Hide the edit button when a user does not have permission. The click event is also squelched in the code to prevent manipulation.
.no-permission {
  & .vai-tree__node-icon-edit,
  .vai-tree__node-action-btn .vai-icon--edit {
    display: none !important;
  }
}

.site-tree-display-none {
  display: none !important;
}

.site-tree-exit-button-container {
  margin-top: 10px;
  text-align: center;
}

.site-tree-editing-border {
  border: 2px solid $vai-color-aqua-medium;
  padding: 20px;
}

.site-tree-editing-border {
  border: 2px solid $vai-color-aqua-medium;
  padding: 20px;
}

.site-tree-exit-button-separator {
  margin-top: 20px;
}

#site-tree-rearrange-message {
  margin-top: 5px;
}

#site-tree-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & nav {
    min-height: auto !important;
  }

  .vai-tree__node {
    transition: none;
    height: 48px;

    &--selected {
      border-top: 2px solid $vai-color-aqua-vaisala;
      border-bottom: 2px solid $vai-color-aqua-vaisala;
    }
  }

  .vai-tree__node--highlighted {
    background-color: var(--vai-color-yellow-light);
  }

  .vai-tree__node--alarming {
    &.vai-tree__node--selected {
      background: #ccebf4;
    }

    & .vai-icon--alert-bell {
      color: $vai-color-red;
    }
  }

  .vai-tree__node--warning {
    &:not(.vai-tree__node--selected) {
      background: linear-gradient(90deg, rgba($vai-color-orange, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(0deg, $vai-color-background-grey, $vai-color-background-grey), rgba(26, 180, 226, 0.35);
    }

    & .vai-icon--alert-bell,
    & .vai-tree__node-alarms {
      color: $vai-color-orange;
    }
  }

  .vai-tree__node--info {
    background: linear-gradient(
        90deg,
        rgba(0, 103, 149, 0.25) 0%,
        rgba(0, 103, 149, 0.15) 15%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(0deg, #f5f6f8, #f5f6f8);

    & .vai-icon--alert-bell,
    & .vai-tree__node-alarms {
      color: $vai-color-aqua-dark;
    }
  }

  .vai-tree__node-alarms {
    font-size: medium;
  }
}
