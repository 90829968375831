@import '@vaisala/rockhopper-design-tokens/variables.scss';

.acknowledge-form-container {
  background-color: var(--vai-color-blue-light-2);
  padding: 5px 10px 5px 10px;
}

.acknowledge-form-message {
  margin-top: 5px;

  .vai-textarea {
    width: 265px;
  }
}

.acknowledge-form-button {
  min-width: 75px;
}