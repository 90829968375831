@import '@vaisala/rockhopper-design-tokens/_variables.scss';

/** Alarms datatable */

.alarms-datatable {
  height: inherit;

  .react-grid-Row:has(.react-grid-Cell[value='CRITICAL']):not(:hover):has([data-is-current='true']) .react-grid-Cell {
    background-color: #f8cece;
  }

  .react-grid-Row:has(.react-grid-Cell[value='MODERATE']):not(:hover):has([data-is-current='true']) .react-grid-Cell {
    background-color: #fff5e6;
  }

  .react-grid-Row:has(.react-grid-Cell[value='INFO']):not(:hover):has([data-is-current='true']) .react-grid-Cell {
    background-color: $vai-color-blue-light;
  }
}

.alarm-list-button {
  padding: 5px 40px;
  text-wrap: nowrap;
}

.alarm-filter-row {
  margin-top: -3px;
}

.margin-bottom-s--forced {
  margin-bottom: $vai-spacing-s !important;
}

.margin-top-s--forced {
  margin-top: $vai-spacing-s !important;
}


// TODO: Remove when rockhopper fixes css specificity bug.
// fix for formItem requiredAsterisk position:absolute being overwritten
.vai-form-item__required-asterisk {
  &.vai-icon {
    &.vai-required-asterisk {
      position: absolute;
    }
  }
}
