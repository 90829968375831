@import '@vaisala/rockhopper-design-tokens/variables.scss';

.highlight {
  background-color: var(--vai-color-yellow-light);
  & .react-grid-Cell:not(:hover) {
    background-color: var(--vai-color-yellow-light)!important;
  }
}

.device-manager.container {
  position: relative;
  height: 100%;
  width: 100%;
}

.icon-width {
  width: 36px;
}

.device-manager {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;

  & .device-manager--list {
    height: 100%;
    overflow-y: hidden;
  }

  & .device-container {
    height: 100%;
    overflow: auto;
  }

  & .device-list-item {
    cursor: pointer;
  }

  & .device-status {
    min-width: 120px;
  }

  & .device-icon {
    min-width: 25px;
  }

  & .search-container {
    margin-bottom: 1rem;
  }

  & .device-filters .filter-select {
    width: 145px;

    .sort .rc-select-selector {
      color: #696969;
      font-style: italic;
    }
  }
}

.properties-stack {
  & .borderless {
    & .vai-table__cell {
      border: 0 !important;
    }
  }
  & .properties-table {
    & tr > td:first-child {
      width: 250px;
    }
    max-width: 400px;
  }
}

.device-manager-status {
  #device-status-title {
    display: flex;
    flex-basis: calc(60%);
  }
  & .device-status__icons {
    & .vai-icon {
      margin: 0.25rem 1rem 0 0;
    }
  }
  & .device-status__active-toggle {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  & .device-status-column {
    display: flex;
    flex-flow: column;
    order: 2;
    padding: 0 1rem;
    flex-basis: 40%;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 1;

    &__title {
      color: $vai-color-grey-medium;
      font-size: 0.925rem;
      font-weight: normal;
      margin-bottom: 0.625rem;
    }

    &--first {
      order: 1;
      flex-basis: 60%;
      padding: 0 1.5rem;
    }
  }

  .device-status-value {
    font-size: 0.88rem;
    line-height: 1rem;
    margin-bottom: 0.75rem;

    &--alert {
      margin-left: -27px;
      color: $vai-color-red;

      .vai-icon {
        margin-right: 9px;
      }
    }

    &--warning {
      color: $vai-color-red;
    }
  }

  .device-datasources {
    margin-top: $vai-spacing-m;
    .datasource {
      display: flex;
      justify-content: stretch;
      align-items: center;
      border: 1px solid $vai-color-grey-light-2;
      margin-bottom: $vai-spacing-s;
      padding: 0.75rem 1rem 0.63rem 0.63rem; // adjust card spacing.

      &::before {
        content: '';
        display: block;
        width: 11px;
        margin: -0.75rem 1rem -0.63rem -0.63rem; // align left status "border" to card outline even if padding is present
        align-self: stretch;
      }

      &--active {
        &::before {
          background: $vai-color-alert-ok;
        }
      }
      &--stale {
        &::before {
          background: $vai-color-alert-alarm;
        }
      }
      &--offline {
        &::before {
          background: $vai-color-alert-error;
        }
      }
      &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
      &__name {
        flex-basis: 40%;
        color: $vai-color-grey-medium;
        margin-bottom: $vai-spacing-xs;
      }
      &__time {
        display: flex;
        color: $vai-color-grey-medium;

        span {
          margin-left: $vai-spacing-m;
        }
      }
      &__timestamp {
        color: $vai-color-blue-dark;
      }
      &__value.vai-heading {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 32px;
        flex-basis: 50%;
      }
      &__location-link {
        a {
          color: $vai-color-aqua-vaisala;
          text-decoration: none;

          .vai-icon {
            color: $vai-color-aqua-dark;
          }
        }
      }
    }
  }

  .device-anchor {
    text-decoration: none !important;

    img {
      margin-left: 0;
    }
  }
}

.device-manages-edit-properties-form-container {
  margin-left: 20px;

  .vai-label--m {
    width: 160px;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
