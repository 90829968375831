@import '@vaisala/rockhopper-design-tokens/variables.scss';

.load-more-rows {
  margin-right: 28px;
  inline-size: 180px;
  padding-block: 8px;
  padding-inline: 16px;
  position: absolute;
  inset-block-end: 8px;
  inset-inline-end: 8px;
  line-height: 35px;
  background: #e6f5fa;
}

#statistics-table.vai-data-table {
  .react-grid-Row > .react-grid-Cell:nth-of-type(1) > .react-grid-Cell__value {
    color: $vai-color-blue-dark;
  }

  .react-grid-Canvas {
    overflow: auto !important;
  }

  .location-wrapper {
    gap: 5px;
    white-space: initial;

    .colored-dot {
      flex-shrink: 0;
    }
  }
}
