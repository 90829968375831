#events {
  .vai-paper {
    margin-top: 0.5rem;
  }

  .content {
    display: flex;
    justify-content: space-around;
    max-height: calc(100vh - 245px);

    @media only screen and (min-width: 960px) {
      max-height: calc(100vh - 190px);
    }

    .table-full-width {
      width: 100%;
    }

    .table-calc-width {
      width: calc(100% - 460px);
    }

    & .detail-view {
      width: 450px;
    }
  }

  .centered-elements-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}