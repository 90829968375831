.select-header-language-container {
  background-color: transparent !important;
  z-index: 1;
  position: absolute;
  right: 0;
}

.select-header-language-title {
  right: 109px
}

.select-header-language-list-avatar {
  margin-right: 30px;
  margin-left: 10px;

}

.select-header-language-title-avatar {
  border: 0.5px solid #808080;
}