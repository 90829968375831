@import '../../variables.scss';


.vai-label--m{
  width: $input-label-width;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.vai-login__paper{
  padding-right: $login-paper-right-padding;
  padding-left: 35px;
}
.input-error {
  color: $vai-color-red;
}
.vai-login__children{
  width:360px;
}
#login-container .vai-login__app-meta-privacy-policy {
  color: #e8e8e8;
}
