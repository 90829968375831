@import '@vaisala/rockhopper-design-tokens/variables.scss';

#time-picker {
  .vai-menu-button__button {
    min-width: 0;
    height: var(--vai-spacing-l);
  }

  .vai-menu-button__list {
    max-height: 150px;
    overflow-y: scroll;
    min-width: 85px;
  }

  .vai-menu-button__list-item {
    width: auto;
    padding: 4px 8px;
    text-align: center;

    &[disabled] {
      background-color: $vai-color-grey-light-2;
      color: $vai-color-grey;
      cursor: auto;
      pointer-events: none;
    }
  }

  .vai-button__text {
    padding: 0;
  }
}
