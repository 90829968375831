#tab-navigation {
  z-index: 1;
  position: relative;
  top: 0.8px;
  left: -1px;

  .vai-tab {
    &--selected {
      background-color: #ffffff;
      border-bottom-color: #ffffff;
      border: 1px solid #dadada;
      border-bottom: none;
    }
    a {
      color: inherit;
    }
  }
}
