@import '../../variables.scss';

#signup-finish-button {
    margin-right: 50px;
}
.vai-login__children {
    width:360px;
}
#phone-input-label {
    display: flex;
}

#signup-setup-profile-form .vai-paper--padding-m {
    padding-left: 35px;
}

#signup-setup-profile-form .vai-label--m {
    width: $input-label-width;
}
.signup-setup-profile-phone-error{
    margin-left: 100px;
    margin-bottom: 20px;
}