@import '@vaisala/rockhopper-design-tokens/_variables.scss';

#generate-report-dialog-id {
  .generate-report-header {
    text-align: center;
  }

  .main-grid-wrapper {
    min-height: 345px;

    .row {
      min-height: inherit;
      margin-bottom: 30px;

      .left-column,
      .right-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .left-column {
        border-right: 1px solid $vai-color-grey;

        .report-form {
          margin-top: 16px;

          .vai-form-item__required-asterisk.vai-icon {
            left: 0;
          }

          .vai-label {
            padding-left: 16px;
          }

          .date-range-wrapper {
            margin-top: 45px;
            margin-bottom: 45px;

            .date-range-title {
              color: $vai-color-blue-dark;
              font-weight: 600;
              font-size: 14px;
              padding-bottom: 10px;
              display: inline-block;
            }

            .date-range {
              gap: 10px;
            }
          }
        }

        .notify-checkbox-wrapper {
          color: $vai-color-blue-dark;
          font-size: 14px;

          .vai-checkbox {
            color: inherit;
          }

          &.inverted {
            .vai-checkbox--disabled.vai-checkbox--checked:before {
              background-color: $vai-color-grey-medium;
            }
          }
        }
      }

      .right-column {
        padding-left: 13px;

        .included-checkbox-wrapper {
          gap: 10px;

          .vai-select {
            padding-left: 27px;
            width: 195px;
          }
        }

        .page-count {
          gap: 3px;
          font-weight: 600;
        }
      }

      .col-title {
        font-size: 18px;
        color: $vai-color-blue-dark;
        font-weight: 600;

        .capitalize {
          text-transform: capitalize;
        }
      }
    }
  }

  .generate-report-bottom-bar {
    position: relative;

    .about-toggle {
      position: absolute;
      left: 0;
      cursor: pointer;
      color: $vai-color-aqua-vaisala;

      span:last-of-type {
        padding-left: 3px;
      }
    }
  }

  .about-content {
    color: $vai-color-blue-dark;
    margin: 20px 0 7px;
    max-height: 150px;
    overflow-y: auto;

    .main-title {
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 10px;
      display: inline-block;
    }

    .about-section {
      padding: 5px 0;
      gap: 3px;

      .sub-title {
        font-weight: 600;
      }
    }
  }
}
