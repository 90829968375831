@import '../../../variables';

.tag-input {
    display: flex;
    flex-wrap: wrap;
    min-height: 24px;
    padding: 0 8px;
    border: 1px solid #d6d8da;
  }

  .tag-input input {
    flex: 1;
    border: none;
    height: 22px;
    font-size: 14px;
    padding: 4px 0 0;
  }

  .tag-input input:focus {
    outline: transparent;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0;
  }

  .tag {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;

  }

  .valid-tag{
    background: $vai-color-blue-light-2;
  }

  .invalid-tag{
    background: $vai-color-pink;
  }

  .tag-title {
    margin-top: 3px;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
  }
