.site-tabs {
  &__container {
    height: 100%;
    width: 100%;
    min-width: 380px;

    & .vai-paper {
      min-height: 0;
    }
  }

}