@import '../../../_variables.scss';

.settings-details {
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: auto;
  padding-right: $vai-spacing-s;

  .dm-accordion-list {
    height: 100%;
    min-height: 0;
  }

  .threshold-accordion {
    &>button {
      background-color: $vai-color-white;
      box-shadow: $vai-shadow-level-1 grey;
    }

    & .vai-accordion__inner-content,
    & .vai-accordion__content {
      background-color: transparent;
    }

    background-color: transparent;
  }
}

.setting-value {
  border-left: 11px solid;
  margin-bottom: -8px;
  padding: 1.125rem 0 1.125rem 0.63rem;

  &--unlinked {
    border-color: $vai-color-aqua-vaisala;
  }

  &--active {
    border-color: $vai-color-alert-ok;
  }

  &--inactive {
    border-color: $vai-color-grey-medium;
  }

  &--stale {
    border-color: $vai-color-alert-warning;
  }

  &__title {
    font-size: 4.38rem;
    font-weight: 600;
    line-height: 40px;
    margin: 0;

    &--measurement {
      font-size: 3.13rem;
    }
  }

  &__settings {
    align-self: flex-end;
    color: $vai-color-aqua-vaisala;
    text-decoration: none;

    &::hover {
      text-decoration: underline;
    }
  }
}

.setting-item {
  color: $vai-color-blue-dark;

  &--error & {
    &__value {
      color: $vai-color-red;
    }
  }

  &__label {
    margin-bottom: 2px;
    color: $vai-color-grey-medium;
  }

  &__value {
    margin-bottom: 2px;
  }

  &__vertical-separator {
    width: 1px;
    height: 32px;
    background-color: #cfcfcf;
    border-style: none;
    margin: 3px 0.75rem;
  }

  &__icon {
    margin: 0 1rem;
  }
}

.settings-data-source {
  gap: 1rem;

  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.settings-alarms {
  width: 100%;
}

.setting-alert-message.vai-body-text {
  color: #df1616;
}

.setting-ok-icon {
  margin-top: 2px;
  margin-left: 1rem;
  color: #33b28e;
}

.setting-alert-icon {
  margin-top: 2px;
  margin-left: 1rem;
  color: #df1616;
}

.setting-location-device-status-container {
  height: 100%;
  padding-top: 15px;
}

.setting-title {
  margin: 1rem 0 0.75rem;
}

.setting-status {
  margin: 1rem 1.5rem 0.75rem;
}

.alarms-accordion-title {
  width: 200px;
  text-align: left;
  margin-left: $vai-spacing-s;
}

.dm-accordion-list {
  .vai-heading {
    width: 100%;
  }
}