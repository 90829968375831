@import '@vaisala/rockhopper-design-tokens/_variables.scss';

:root {
  --threshold-width: 100px;
}

#overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  margin: 0.75rem;
  color: #fff;
  z-index: 10;
  pointer-events: none;
}

#chart-wrapper {
  .threshold {
    left: 50px;
    color: white;
    position: absolute;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;
      width: var(--threshold-width);
      border-bottom: 2px dashed red;
    }
  }

  .uplot {
    display: inline-block;
    vertical-align: top;
    width: min-content;
  }

  .u-over {
    box-shadow: 0px 0px 0px 0.5px #ccc;
  }

  .u-legend {
    text-align: left;
    padding-left: 50px;
  }

  .u-inline tr {
    margin-right: 8px;
  }

  .u-label {
    font-size: 12px;
  }

  .u-wrap {
    position: relative;
  }

  .u-tooltip {
    font-size: 10pt;
    position: absolute;
    background: #fff;
    display: none;
    border: 2px solid $vai-color-aqua-medium;
    padding: 4px;
    pointer-events: none;
    z-index: 100;
  }

  .tooltip-content {
    width: 240px;

    .timestamp {
      text-align: center;
      margin: 0;
      color: $vai-color-blue-dark;
    }

    .content-values {
      display: flex;
      flex-direction: column;
      width: 100%;

      .row {
        display: flex;
        gap: 10px;
        padding: 0 10px;

        &.group-header {
          justify-content: flex-end;

          span {
            width: 50%;
            text-align: center;
            padding: 16px 0 8px;
            color: $vai-color-grey-medium;
          }
        }

        .col {
          width: 50%;
          font-size: 13px;

          &:first-child {
            color: $vai-color-blue-dark;
          }

          &:last-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .value-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            span:last-child {
              color: $vai-color-blue-dark;
            }
          }

          .data-unit {
            color: $vai-color-grey-medium;
          }
        }
      }
    }
  }

  .axis-label {
    top: -40px;
    left: 18px;
    position: absolute;
    font-weight: bold;
  }

  .u-cursor-x {
    border-right: 1px dashed $vai-color-aqua-vaisala;
  }

  .visible-locations-num {
    color: $vai-color-blue-dark;
    font-weight: 600;
    font-size: 16px;
  }
}
