@import '../../../../../../variables';

.threshold-notifications-form {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .alarm-setting-form-header-row .vai-col {
    padding: $vai-spacing-xs $vai-spacing-m !important;
  }

  @media (max-width: $vai-breakpoint-m) {
    & .vai-col {
      padding: $vai-spacing-xs $vai-spacing-s !important;
    }
  }

  & .save-button {
    margin-right: 12px;
  }

  & .confirm-cancel-button {
    margin-right: 12px;
    width: 120px;
  }

}