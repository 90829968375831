.add-group-members-picture-icon {
    font-size: 24px;
}

.add-group-members-heading {
    margin-top: 0px;
}

.add-group-members-picture-icon {
    margin-top: 5px;
}

.add-group-members-container {
    height: 400px;
}

.add-group-members-button-container {
    text-align: center;
}

.add-group-members-horizontal-separator {
    margin-top: 200px;
}

.add-group-members-field-container {
    margin-left: 5px;
}

.add-group-members-users-container {
    overflow-y: auto;
    height: 300px;
    border: solid 1px #b5b5b5;
}

.add-group-members-email-invite-container {
    overflow-y: auto;
}

.add-group-members-select-existinguser-msg {
    color: #696969;
}

.add-group-members-add-to-msg {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
}

.add-group-members-group-name {
    color: #009ac8;
}

.add-group-members-checkbox {
    width: 45px;
}

.add-group-members-empty-list {
    width: 100%;
    height: 295px;
    color: #696969;
    padding: 0 4px;
    margin-left: 4px;
}