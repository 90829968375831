@import '../../variables.scss';

#edit-zone-and-location-dialog,
#create-zone-and-location-dialog {
  .modal-content {
    padding-left: 1rem;
  }

  .vai-label--m {
    width: 120px;
  }
}

#edit-zone-and-location-dialog-description,
#zone-and-location-dialog-description {
  width: 155px;
}

.zone-and-location-dialog-astrisk {
  position: absolute;
  left: -14px;
}

.zone-and-location-dialog-parent-zone {
  margin-right: 50px;
}

.zone-and-location-dialog-parent-zone-name {
  overflow-wrap: break-word;
}

.zone-and-location-dialog-parent-name-container {
  position: relative;
  margin-bottom: 20px;
}

.zone-and-location-dialog-icon {
  margin-right: 2px;
}

.zone-and-location-visibility-hidden {
  visibility: hidden;
}

#edit-zone-and-location-delete .vai-button__text {
  width: max-content;
}

.edit-zone-and-location-delete-text {
  font-size: 13px;
  color: #696969;
}

.show-inactive-locations-container {
  text-wrap: nowrap;
}