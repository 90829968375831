@import '../../../../_variables.scss';

.alarm-display-row {
  margin-bottom: $vai-spacing-l !important;

  & .alarm-level-heading {
    margin-top: 0;
    margin-bottom: $vai-spacing-s
  }

  & .alarm-notification-heading {
    margin-bottom: $vai-spacing-s;
  }
}