.alarm-priority-select,
.alarm-priority-select .rc-select-selector,
.alarm-priority-select input {
    background-color: transparent !important;
}

.alarm-priority-selector-option>:first-child {
    margin-right: 15px;
}

.alarm-priority-selector-option {
    font-size: medium;
}

.alarm-priority-selector-list {
    height: 105px;

    & .rc-select-item {
        padding: 5px;
    }
}