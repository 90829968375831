.mfa-explanation {
    margin-left: 0px;
    margin-bottom: 10px;
}
#mfa-button-resend{
    padding-left: 0px;
    margin-left: -6px;
}
#mfa-button-back{
    padding-left: 1px;
    margin-left: -5px;
}