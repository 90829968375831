@import '../../../../../../variables';

.device-alarm-notifications-form {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .alarm-setting-form-header-row .vai-col {
    padding: $vai-spacing-xs $vai-spacing-m !important;
  }

  & .notification-group-select {
    height: 25px;
  }

  & .device-alarm-rule-display {
    & h4 {
      margin: 0;
      margin-right: $vai-spacing-s;
    }
  }

  @media (max-width: $vai-breakpoint-m) {
    & .vai-col {
      padding: $vai-spacing-xs $vai-spacing-s !important;
    }
  }
}