.react-resizable {
  position: relative;
}

.custom-handle {
  position: absolute;
  opacity: 0.75;
  border-radius: 4px;
  top: 90px;
  right: -15px;
  cursor: ew-resize;
  width: 12px;
  height: 50px;
  background: white;
  border: 1px solid #696969;
  border-radius: 8px;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.custom-handle > span {
  display: inline-block;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #696969;
  text-shadow: 1px 0 1px #696969;
  line-height: 3.5px;
}

.custom-handle > span::after {
  content: '. . . . . . .';
}

#site-search-input {
  max-width: 500px;
}

#site-buttons-flex-container {
  max-width: 320px;
}
