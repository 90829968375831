@import '../../variables.scss';

#groups-edit-button {
  border: none;
  background-color: transparent;
}
#groups-edit-button:hover {
  border: none;
  background-color: transparent;
}
.user-list-paper,
.user-profile-paper {
  height: 100%;
}
.user-list-paper {
  display: flex;
  flex-direction: column;
}
.count-label {
  font-size: 13px;
  color: $vai-color-grey-medium;
}
.user-download-icon {
  font-size: 20px;
  margin-left: auto;
}
.user-group-list-table-container {
  overflow-y: auto;
  overflow-x: hidden;
}
.user-and-group-table {
  width: 99%;

  &__groupname:hover {
    color: $vai-color-aqua-dark;
  }
}
.user-and-group-table-wrapper {
  // height: 100%;
  min-width: 400px;
  overflow-y: auto;
}
.user-profile-picture-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.my-profile-icon {
  font-size: 42px;
}
.user-full-name {
  margin-left: 20px;
  font-size: 21px;
}
.vertical-separator {
  width: 1px;
  height: 97%;
  background-color: #cfcfcf;
  border-style: none;
}
.user-profile-info-container {
  height: calc(100vh - 192px);
}
.user-borderless-table td {
  border: none;
}
.group-profile-paper {
  overflow: auto;
  height: 100%;
}
.user-header {
  font-size: 18px;
  font-weight: bold;
  width: 150px;
}
.user-top-right-button {
  white-space: nowrap;
}
.user-top-right-button-container {
  margin-left: auto;
  margin-right: 10px;
  width: 150px;
}
.user-center-align {
  text-align: center;
}
#user-list-table tr {
  cursor: pointer;
}
#user-list-table tr:hover {
  background-color: $vai-color-blue-light-2;
}
#group-list-table tr:hover {
  background-color: $vai-color-blue-light-2 !important;
}
.user-selected-row {
  background-color: $vai-color-blue-light-2 !important;
  border: $vai-color-aqua-vaisala solid !important;
  border-width: 2px 0px 2px 0px !important;
}
.group-users-selected-row {
  background-color: $vai-color-blue-light-2;
}
#group-list-table {
  & .groups-edit-button {
    visibility: hidden;
  }
  & .user-selected-row .groups-edit-button {
    visibility: visible;
  }
}
.user-row-active {
  color: black;
}
.user-and-group-row-deactive {
  color: gray;
  background-color: $vai-color-grey-light !important;
}
.user-deletion-button {
  margin-top: 100px;
}
.group-group-Title {
  font-size: $vai-font-size-xl;
}
.group-edit-description {
  font-size: 13px;
  color: $vai-color-grey-medium;
}
#group-activation-toggle .vai-toggle__label {
  margin-left: 20px;
}
.groups-table-checkbox-cell {
  width: 20px;
}
.display-none {
  display: none;
}
#user-visibility-icon-button {
  border: none;
  background-color: transparent;
}
#show-rights-summary-button {
  display: flex;
  margin: 0 auto;
  align-items: center;
}
.site-tree-accordion-container {
  overflow-y: auto;
  overflow-x: auto;
}

.group-user-list {
  &-table-wrapper {
    overflow: auto;
    transition: height 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &__username:hover {
    color: $vai-color-aqua-dark;
  }
}

.invite-users-groups-container {
  .user-and-group-table {
    table-layout: fixed;
    width: 289px;
  }
  .vai-checkbox {
    display: flex;
    align-items: center;
    flex: 0 1 1;

    // adjust position for -webkit-inline-box alignment
    &::before {
      top: 4px;
    }
    &::after {
      top: 5px;
    }
  }
  .invite-user-group-label {
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
    flex-basis: calc(100% - 1rem); // enforces that the multiline label never takes up space from the checkbox
  }
}
.shadow-row {
  position: sticky;
  top: 35px; /* Adjust this value to match your header height */
  background-color: #f2f2f2; /* Background color to match the header */
  z-index: 0; /* Behind the main header row */
  border-bottom: 2px solid #ddd; /* Bottom border */
}

.vai-margin-right-75-rem {
  margin-right: 0.75rem;
}

.deactivate-row-background {
  background-color: $vai-color-grey-light;
}