@import '../../../../variables';

.notification-group-select {
  position: relative;
  height: 40px;

  & .notification-group-selector {
    height: 100%;
    cursor: pointer;

    & .notification-group-selector-display {
      height: 100%;
      overflow: hidden;
    }
  }

  & .group-select {
    border: 1px solid $vai-color-aqua-vaisala;
    width: 100%;

    & .selected-group-display {
      margin-bottom: $vai-spacing-m;
      margin-left: 0;
    }

    & .search-field {
      margin-bottom: $vai-spacing-l;

      & input {
        width: 100%;
      }

      & .vai-input-field__wrapper {
        width: 100%;
      }
    }

    position: absolute;
    background-color: white;
    padding: $vai-spacing-s;
    z-index: 100;

    & .group-list {
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: $vai-spacing-s;

      & ul {
        list-style: none;
        padding: 0;
      }
    }
  }

  .cannot-acknowledge-alarms {
    margin-top: $vai-spacing-s;
    margin-bottom: $vai-spacing-s;
  }

  .group-dropdown-group-details {
    padding-left: 0 !important;
  }

  .group-description {
    margin: $vai-spacing-s 0;
    color: $vai-color-grey-medium;
  }

  & .vai-grid>.vai-row {
    width: 120%;
    padding-left: 0;
  }

  & .vai-col {
    border-left: none;


    &>.vai-row {
      margin: 0;

      &>.vai-col {
        padding: 0;
      }
    }
  }


  & .vai-grid>.vai-row {
    margin-bottom: $vai-spacing-xxs;
    margin-top: $vai-spacing-xxs;
  }
}