.group-rights-infotip {
  max-width: 400px !important;
}

.checked-inverted[data-readonly='true'] {
  .vai-checkbox--checked {
    &::before {
      background-color: transparent !important;
      border: none;
    }

    &::after {
      color: #003462 !important;
    }
  }
  .vai-checkbox--disabled {
    &::after {
      color: #003462;
    }
  }
}
