@import '@vaisala/rockhopper-design-tokens/variables.scss';

.locations-list-wrapper {
  display: flex;
  flex-direction: column;
  .locations-filters {
    gap: 5px;

    .vai-button,
    .vai-menu-button {
      width: 33.3%;
    }

    .vai-menu-button .vai-button {
      width: 100%;
    }
  }

  .no-results-text {
    color: $vai-color-blue-dark;
    font-weight: 600;
    display: inline-block;
    padding-top: 16px;
  }
}
