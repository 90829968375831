.data-source--list {
    display: flex;
    flex-direction: column;
    height: max-content;
    border: 1px solid var(--vai-color-grey-light-2);
    min-height: 300px;
    max-height: calc(100vh - 313px);
    overflow-y: auto;
}

.confirmation-modal-meas-icon {
    margin-right: 11px;
}

.hide-link-data-source-message {
    display: none;
}

.status-msg-icon {
    color: #003462; 
}
.link-data-source-dialog {
  &__title {
    display: flex;
    word-break: break-word;
    align-items: center;
  }
  &__icon {
    flex: 0 1 1%;
  }
}