@import '../../../../../../variables';

.device-alarm-rules-form {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 85%;

  &.context-open {
    padding-right: $vai-spacing-s;
  }

  & .rule-name-field {
    height: 100%;

    & h4 {
      margin: 0;
    }

    & div:first-child {
      margin-right: $vai-spacing-s;
    }
  }
  .hide-contextual-help-button {
    float: right;
  }
}