.alarm-display {
    min-width: 190px;
    font-size: large;

    h3 {
        margin: 0;
    }

    >* {
        padding: 0px 10px;
    }
}