.add-groups-to-users-picture-icon {
    font-size: 24px;
}

.add-groups-to-users-heading {
    margin-top: 0px;
}

.add-groups-to-users-picture-icon {
    margin-top: 5px;
}

.add-groups-to-users-container {
    height: 400px;
}

.add-groups-to-users-button-container {
    text-align: center;
}

.add-groups-to-users-horizontal-separator {
    margin-top: 200px;
}

.add-groups-to-users-users-container {
    overflow-y: auto;
    height: 300px;
    border: solid 1px #b5b5b5;
}

.add-groups-to-users-email-invite-container {
    overflow-y: auto;
}

.add-groups-to-users-select-existinguser-msg {
    color: #696969;
}

.add-groups-to-users-add-to-msg {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
}

.add-groups-to-users-group-name {
    color: #009ac8;
}

.add-groups-to-users-checkbox {
    width: 45px;
}

.add-groups-to-users-empty-list {
    width: 100%;
    height: 295px;
    color: #696969;
    padding: 0 4px;
    margin-left: 4px;
}

.add-groups-to-users-users-list-table {
    width: 100%;
}