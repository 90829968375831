.location-icon {
  width: 55px;
  height: 43px;
  position: relative;
  margin-bottom: 1rem;

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__link {
    position: absolute;
    top: 6px;
    left: 13px;
    z-index: 1;
    background:#fff;
    border-radius: 100%;
  }
}