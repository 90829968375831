@import '../../_variables.scss';

.site-table {
  .cell {
    &--icon {
      display: flex;
      justify-content: center;
    }

    &--deactive {
      color: $vai-color-grey-medium;
      font-style: italic;
    }

    > .vai-icon {
      &.icon--disabled {
        color: $vai-color-grey-medium !important; // temporary disabled style for action
      }
    }
  }
  // Add 100% height so we can create a click area that fills the whole cell for location names as they are used as links.
  .react-grid-Cell__value {
    & > div:has(.location-cell) {
      height: 100%;
    }

    .location-cell {
      display: flex;
      flex-flow: row;
      height: 100%;
      align-items: center;

      a {
        text-decoration: none;
        color: inherit;
      }
      i {
        color: $vai-color-grey-medium;
      }
    }
  }

  &__container {
    height: 100%;
  }

  & .react-grid-Row:not(:has(.highlight)) .react-grid-Cell:not(:has(.icon--disabled)):not(:hover) {
    background-color: white;
  }

  & .react-grid-Row:has(i[data-inactive]) .react-grid-Cell:not(:hover) {
    background-color: $vai-color-grey-light !important;
  }

  & .react-grid-Row.react-grid-Row--odd:has(i[data-inactive]):not(:has(.highlight)) .react-grid-Cell:not(:hover) {
    background-color: $vai-color-grey-light !important;
  }

  & .react-grid-Row.react-grid-Row--odd:not(:has(.highlight)) .react-grid-Cell:not(:hover) {
    background-color: white;
  }

  & .react-grid-Row.react-grid-Row--odd .react-grid-Cell {
    background-color: white;
  }

  & .react-grid-Row:hover:not(:has(.highlight)) .react-grid-Cell:not(:hover) {
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  & .react-grid-Canvas {
    overflow: auto !important;
  }

  [data-highlighted='true'] {
    background-color: var(--vai-color-yellow-light) !important;

    & .react-grid-Cell:not(:hover) {
      background-color: var(--vai-color-yellow-light) !important;
    }

    &.react-grid-Row:has(i[data-inactive]) .react-grid-Cell:not(:hover) {
      background-color: var(--vai-color-yellow-light) !important;
    }
  }
}
