@import '@vaisala/rockhopper-design-tokens/variables.scss';

.acknowledge-message-user-container {
    margin-top: 7px;
    min-height: 50px;
    height: auto;
    padding: 10px 0 10px 5px;
    background-color: var(--vai-color-blue-light-2)
}

.acknowledge-message-system-container {
    margin-top: 7px;
    min-height: 50px;
    height: auto;
    padding: 10px 0;
    border: 1px solid var(--vai-color-grey);
}

.acknowledge-message-ok-icon {
    margin-left: -12px;
    margin-top: -2px;
}

.acknowledge-message-ok-time {
    color: var(--vai-color-grey-medium)
}