.threshold-details-container {

    .vertical-separator {
        height: calc(100% - 0.5em);
    }
}

.threshold-details-stack {
    & .borderless {
        & .vai-table__cell {
            border: 0 !important;
        }
    }

    & .threshold-details-table {
        & tr>td:first-child {
            width: 150px;
            font-weight: bold;
        }

        max-width: 500px;
    }
}

#alarms-thresholdAlarmDetails-modalContainer {

    & .steps-container,
    & .modal-content,
    & .modal-container {
        height: 100%;
    }

    & .modal-content {
        min-height: 0;

        &>div {
            height: 100%;
        }
    }

    .vai-modal__box--l {
        width: 850px;
        height: 700px;
    }

    .vai-paper {
        min-width: 778px;
        padding: 16px 36px;
        box-shadow: none;
        height: 100%;
    }

    .alarm-details-empty-state-container {
        padding-top: 70px;
    }
    #alarms-thresholdAlarmDetails-acknowledge-button{
        margin-top: 11px;
    }
}

.alarms-thresholdAlarmDetails-acknowledgment-container {
    width: 100%;
    max-height: 550px;
    overflow-y: auto;
    padding-right: 5px;
    margin-top: 10px;
}