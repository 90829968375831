@import '@vaisala/rockhopper-design-tokens/variables.scss';

.selected-locations-wrapper {
  margin: 10px 0;
  overflow: auto;

  .node {
    color: $vai-color-blue-dark;
    margin: 16px 0;
    font-weight: 600;

    .visibility-icon {
      margin-right: 3px;
      margin-top: 3px;
    }
  }

  .site-node {
    gap: 3px;
  }

  .location-node {
    margin: 5px 5px 5px 0;
    position: relative;
    cursor: pointer;

    .colored-line {
      position: absolute;
      content: '';
      height: 100%;
      width: 7px;
      top: 0;
      left: 0;
    }

    &.invisible-node {
      background: linear-gradient(0deg, $vai-color-grey-light-2, $vai-color-grey-light-2), $vai-color-white;

      .colored-line {
        background-color: $vai-color-grey-light-2 !important;
      }
    }

    &.show-threshold {
      background: $vai-color-blue-light;
    }
  }
}
