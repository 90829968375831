@import '@vaisala/rockhopper-design-tokens/_variables.scss';

#accordion-tree {
  .vai-accordion {
    border: 0;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    &.partially-checked {
      .vai-checkbox {
        &::after {
          content: '';
        }

        &::before {
          padding: 2px;
          background-clip: content-box;
          background-color: $vai-color-aqua-vaisala;
        }
      }
    }

    .node-title {
      color: $vai-color-blue-dark;
      top: -3px;
      position: relative;

      &.site-node {
        font-weight: 600;
      }

      .node-text {
        margin: 0 5px;
      }
    }
  }

  .vai-accordion__inner-content {
    padding: 0 0 16px 24px;
  }

  .vai-accordion__link {
    background-color: #f5f6f8;

    &:focus {
      box-shadow: none;
    }

    .vai-accordion__summary {
      min-width: fit-content;
    }
  }

  .site-header {
    >.vai-accordion__link {
      background-color: #ffffff;
    }
  }

  .last-child-wrapper {
    padding: 12px 0 12px 16px;
  }

}