@import '../../../_variables.scss';

.statuslist {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }
  &__description {
    padding: 0 0 0.5rem;
    font-weight: 400;
    color: $vai-color-blue-dark;
    overflow-wrap: break-word;
  }
}
