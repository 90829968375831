@import '../../../../../../variables';

.threshold-levels-form {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 85%;

  &.context-open {
    padding-right: $vai-spacing-s;
  }



  & #green-zone {
    background-color: $vai-color-mint-green;
    margin-bottom: $vai-spacing-m;
    opacity: .27;
    height: 35px;
  }



  .alarm-display {
    justify-content: space-between;
    padding: 0 $vai-spacing-s
  }

  .hide-contextual-help-button {
    float: right;
  }
}

.alarm-threshold-dialog-location-name {
  overflow-wrap: break-word;
  word-wrap: break-word;
}