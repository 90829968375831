@import '../../../variables.scss';

#edit-grouprights-modal {
  .vai-modal__box--l {
    width: 950px;
    height: 600px;

    @include respond(md) {
      min-width: 90%;
      max-width: 90%;
    }

    @include respond(sm) {
      min-height: 90%;
      max-height: 90%;
    }
  }

  .vai-paper {
    min-width: 878px;
    padding: 16px 36px;
    box-shadow: none;

    @include respond(md) {
      min-width: 90%;
      padding: 13px 14px;
    }

    @include respond(sm) {
      min-width: 90%;
    }

    .vai-accordion__inner-content {
      padding: 0;
    }
  }

  .groups-rights-title {
    margin: $vai-spacing-xxs 0;
  }

  .modal-header {
    margin-bottom: $vai-spacing-xxs;
  }

  &.groups-rights-accordion {
    max-height: 100%;

    &--parent {
      max-height: 410px;
      overflow: auto;
    }

    &--small {
      max-height: 210px;
      overflow: auto;
    }

    & h4 {
      text-align: left;
      margin: 0;
    }
  }

  .groups-rights-accordion-height {
    min-height: 30px;
  }

  .group-right--column-padding {
    padding: 4px;
  }

  .groups-rights-children-container-margin {
    margin-left: 10px;
  }

  .groups-rights--accordion-margin {
    margin-left: 5px;
  }

  .groups-rights-dialog-heading {
    text-align: center;
  }

  div[role='dialog'] {
    display: flex;
    flex-direction: column;
    height: 100%;

    .vai-paper,
    .modal-container {
      height: 100%;
      overflow: hidden;
    }

    .modal-content {
      overflow: hidden;
      margin: 10px 0;
      height: 100%;
      flex-direction: column;
      display: flex;
    }

    .scrollable-content {
      overflow: auto;
      height: 100%;
      padding-right: $vai-spacing-xs;

      .checkbox-container {
        padding: $vai-spacing-s;
      }

      .section-title {
        margin: 0;
        margin-top: $vai-spacing-s;
        padding: $vai-spacing-s;
      }
    }
  }

  .vai-accordion--expanded {
    background-color: transparent;
  }
}

.vai-accordion__content {
  overflow: visible !important;
}
