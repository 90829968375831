@import '@vaisala/rockhopper-design-tokens/_variables.scss';

.select-locations-header {
  text-align: center;
}

.select-locations-number {
  color: $vai-color-blue-dark;
  font-weight: bold;
  margin-bottom: 5px;
}

.select-locations-info-text {
  color: $vai-color-grey-medium;
}

.select-locations-accordion-wrapper {
  border: 2px solid black;
  height: 350px;
  overflow-y: auto;
}
