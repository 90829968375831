@import "../../../node_modules/@vaisala/rockhopper-design-tokens/variables.scss";


#change-password-dialog {
  .vai-heading {
    text-align: center;
    margin-top: 0;
  }

  .vai-separator {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .buttons-container {
    margin-top: 20px;
    text-align: center;
  }

  .input-error {
    color: $vai-color-red;
  }

  .includes-error > div {
    border-color: $vai-color-red;
  }
}
