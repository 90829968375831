// header sidebar buttons
.sidebar-menu-item {
  margin: 0;
  width: 57px;
  justify-content: center;

  display: flex;

  @media (max-width: 768px) {
    display: none;
  }

  &__mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      margin-right: -1rem;
    }
  }
}

.vai-drawer {
  transition: transform 0.1s ease-out;

}

.vai-drawer--animated {
  transition: transform 0.22s ease-in-out;
}

// tabs inside drawer
#drawer-tabs {
  .vai-tab {
    border-left: 1px solid var(--vai-color-grey-light);
    border-right: 1px solid var(--vai-color-grey-light);
    border-bottom: 1px solid var(--vai-color-grey-light-3);
    color: var(--vai-color-fg);
    margin-bottom: -1px;


    &:focus {
      outline: none;
      box-shadow: none;
    }

    &--selected {
      border-bottom: 1px solid var(--vai-color-white);
      border-left: 1px solid var(--vai-color-grey-light-3);
      border-right: 1px solid var(--vai-color-grey-light-3);
      background: white;
      outline: none;

    }
  }

  .vai-nav-tabs {
    &__tabs-row {
      background: var(--vai-color-grey-light);
      border-bottom: 1px solid var(--vai-color-grey-light-3);
      justify-content: flex-end;
      padding-right: var(--vai-spacing-m);
    }

    // content inside drawer
    &__tab-content {

      .profile-stack,
      .sidebar__tour-buttons {
        button {
          padding: 0;
          outline: none;
          border: none;
          box-shadow: none;
          text-align: left;
          justify-content: flex-start;
          color: var(--vai-color-fg);

          &:hover {
            background: none;
            color: var(--vai-button-color-bg-primary);

            .vai-button__icon-start {

              color: var(--vai-button-color-bg-primary);
            }
          }
        }
      }

      .profile-grid {
        .vai-row {
          padding: 0 0 .5rem 0;
        }
      }
    }
  }

  .topictitle1 {
    margin: 0;
  }

  .sidebar__hide-button {
    margin-left: auto;
    position: absolute;
    margin-top: 5px;
    right: 0;
    justify-content: flex-end;
    min-width: auto;

    .vai-button__icon-end {
      margin: 0 6px -1px 0;
    }
  }

  .sidebar__tab-content {
    margin-top: 0.5rem;
  }

  .csa-stack {
    .csa-button {
      align-self: flex-start;
      margin-left: -4px;
    }
  }

  .csa-version__heading {
    margin-top: 2rem;
  }

  .sidebar-status-icon{
    position: absolute;
    right: 6px;
    top: 8px;

  }
}
