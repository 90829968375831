.invite-users-container{
    height: 450px;

    .vertical-separator {
      height: calc(100% - 0.5em);
    }
}
.invite-users-horizontal-separator{
    margin-top: 200px;
}
.invite-users-field-container{
    margin-left: 5px;
}
.invite-users-groups-container{
    overflow-y: auto;
    height:355px;
}
.invite-users-email-invite-container{
    overflow-y: auto;
    height: 364px;
    margin-top: 16px;
}