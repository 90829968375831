@import '@vaisala/rockhopper-design-tokens/_variables.scss';

#confirmation-dialog-id {
  .confirmation-header {
    text-align: center;
  }

  .confirmation-content {
    gap: 15px;
  }
}
