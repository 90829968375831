.picture-uploader {
  margin-bottom: 24px;
}
.picture-uploader-file-input-field{
  display: none;
}

.picture-uploader-img{
  width: 70px;
  height: auto;
}

.picture-uploader-change-picture-button{
  margin-left: 28px;
  padding: 0 4px;
}

