@import '../../../variables';

.user-effective-rights-dialog {
  &,
  .vai-paper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .rights-accordion-tree {
    height: 100%;
    overflow: auto;
    padding-right: $vai-spacing-xs;
  }
}
.user-effective-rights-header {
  text-align: center;
  font-size: 18px;
}
.user-effective-rights-body {
  line-height: 25px;
}
.user-effective-rights-description {
  color: #808080;
}
.user-effective-rights-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 7px;
}
