@import '@vaisala/rockhopper-design-tokens/variables.scss';

.acknowledge-message-alert-required-container {
    margin-top: 7px;
    min-height: 50px;
    padding: 15px 0;
    background-color: var(--vai-color-yellow-light);
}

.acknowledge-message-alert-no-acknowledgement-container {
    margin-top: 7px;
    min-height: 50px;
    padding: 15px 0;
    background-color: var(--vai-color-grey-light-2);
}